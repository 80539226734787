.apply {
  min-height: 100vh;

  iframe {
    animation: $a-fade-in-up-delay;
    width: 110%;
    width: calc(100% + 2rem);
    margin: 0 -1rem;
    height: 60rem;
  }
}
