$content-width: 70rem;

.main {
  width: 100%;
  position: relative;
}

section {
  padding: 6rem 0;
  width: 100%;
  position: relative;
  z-index: 10;

  &:first-child {
    padding-top: 12rem;
  }

  &:last-child {
    padding-bottom: 12rem;
  }
}

.content {
  @include clearfix;
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 5.5rem;

  &.content-small {
    max-width: 50rem;
  }

  &.content-nav {
    max-width: unset;
    justify-content: space-between;
    width: 100%;
    padding-left: 5.5rem;
  }
}

.two-up {
  display: flex;

  > div {
    width: 50%;
  }
  &.with-illo-right {
    margin-right: -5.5rem;

    .text-content {
      padding-right: 2rem;
    }
  }

  .tab { 
    margin-left: 40px; 
  }

  &.with-illo-left {
    .text-content {
      padding-left: 2rem;
    }
  }
}

.centered {
  display: flex;
  text-align: center;

  > div {
    width: 100%;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .two-up {
    flex-direction: column;
    margin: 0 !important;

    > div {
      width: 100%;
      padding: 0 !important;
    }

    &.with-illo-left {
      flex-direction: column-reverse;
    }

  }
  .content {
    padding: 0 2.5rem;
  }
}