.background-gradients {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: visible;

  .inner {
    position: relative;
    width: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  .gradient {
    z-index: 1;
    display: block;
    position: absolute;
  }

  .light-blue {
    top: 0;
    left: 100%;
    width: 60rem;
    height: 60rem;
    animation: $a-fade-in-left;

    svg {
      transform: translateX(-25%);
    }
  }

  .green {
    bottom: 0;
    left: 0;
    width: 60rem;
    height: 60rem;
    animation: $a-fade-in-right;

    svg {
      transform: translateX(-65%);
    }
  }

  .dark-blue {
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vw;

    svg {
      transform: translate(35%, 75%) rotate(45deg)
    }
  }

  .apply-dark-blue {
    animation: $a-fade-in-right-fast;
    bottom: 0;
    right: 0;
    width: 140vw;
    height: 140vw;

    svg {
      transform: translate(0, 25%) rotate(45deg);
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .background-gradients {
    .light-blue {
      top: 15%;
      height: 80vh;
      width: 80vh;
    }

    .dark-blue {
      width: 175vw;
      height: 175vw;
      svg  {
        transform: translate(35%, 70%) rotate(45deg);
      }
    }

    .apply-dark-blue {
      bottom: auto;
      top: 0;
      width: 240vh;
      height: 240vh;

      svg {
        transform: translate(20%, -10%) rotate(45deg);
      }
    }
  }
}
