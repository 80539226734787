// Font weights
$extra-bold: 800;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;

$sans: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;

h1, %h1 {
  font-size: 3rem;
  font-weight: $extra-bold;
  line-height: 1.375;
  margin-bottom: 2rem;
  color: $black;
}

h2, %h2 {
  font-size: 1rem;
  font-weight: $regular;
  letter-spacing: 4px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

h3, %h3 {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
}

h4, %h4 {
  font-size: 1rem;
  font-weight: $regular;
}

h5, %h5 {
  font-size: 0.75rem;
  text-transform: uppercase;
}

p, .p {
  font-size: 1.25rem;
  font-weight: $regular;

  + p {
    margin-top: 1rem;
  }
}

strong {
  font-weight: $medium;
}

@media screen and (max-width: $phone-landscape-max) {
  h1, %h1 {
    font-size: 1.75rem;
  }

  h4, %h4 {
    font-size: 1rem;
  }
}
