
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

@keyframes fade-in-up-delay {
  0% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};


@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};


@keyframes fade-in-right-delay {
  0% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  60% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

@keyframes fade-in-left-delay {
  0% {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  60% {
    opacity: 0;
    transform: translate3d(1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

@keyframes fade-in-right-fast {
  0% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  60% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
};

$a-fade-in-up: fade-in-up 0.75s ease;
$a-fade-in-right-fast: fade-in-right-fast 0.75s ease;
$a-fade-in-down: fade-in-down 1.25s ease;
$a-fade-in-left: fade-in-left 1s ease;
$a-fade-in-right: fade-in-right 1s ease;
$a-fade-in-right-delay: fade-in-right-delay 1.25s ease;
$a-fade-in-left-delay: fade-in-left-delay 1.25s ease;
$a-fade-in-up-delay: fade-in-up-delay 1s ease;
