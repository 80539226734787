nav {
  padding: 1.5rem 0;
  animation: $a-fade-in-down;
  width: 100%;
  position: relative;
  z-index: 10;

  .nav {
    display: flex;
    align-items: center;
  }

  .spacer {
    flex: 1;
  }

  a {
    @extend %h4;
  }

  li.logo {
    flex-shrink: 0;
    margin-left: -4rem;
  }

  img {
    height: 5rem;
  }
}

footer {
  padding: 2.5rem 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  text-align: right;

  .content {
    display: flex;
    justify-content: flex-end
  }

  p {
    color: $white;
    font-size: 0.875rem;

    + p {
      margin-top: 0.5rem;
    }
  }
}

.button {
  margin: 2rem 0;
  background-color: $orange;
  width: 100%;
  padding: 1rem 1.5rem;
  display: block;
  border-radius: 0.5rem;
  text-align: center;
  color: $black;
  transition: 0.3s ease all;
  text-transform: uppercase;

  &:hover {
    background-color: darken($orange, 10%);
    color: $black;
  }
}

.illustration {
  position: relative;

  &.illo-1 {
    margin-top: 6rem;

    .illo-1-clouds {
      animation: $a-fade-in-right;
    }

    .illo-1-plants-right {
      animation: $a-fade-in-left;
    }

    .illo-1-plants-left {
      animation: $a-fade-in-left;
    }

    .illo-1-red-shirt-woman {
      animation: $a-fade-in-right-delay;
    }

    .illo-1-table {
      animation: $a-fade-in-left;
    }

    .illo-1-green-shirt-guy {
      animation: $a-fade-in-left-delay;
    }
  }

  &.illo-2 {
    height: 20rem;
    animation: $a-fade-in-up;

    svg {
      transform: translateX(-2rem);
    }
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: auto;
  }
}

.hero {
  position: relative;

  .text-content {
    animation: $a-fade-in-up;
  }
}

.about {
  padding: 1rem 0 1rem;
}

.end {
  padding: 5rem 0 18rem;
}

@media screen and (max-width: $phone-landscape-max) {
  .nav {
    li.logo {
      margin-left: 10;
    }
  }

  .hero {
    padding-bottom: 3rem;
  }

  .about {
    padding: 0 0 12rem;
  }

  .end {
    padding: 1rem 0 12rem;
  }

  .illustration {
    &.illo-1 {
      height: 20rem;
      margin: 1rem auto 0;
    }

    &.illo-2 {
      height: 30rem;
      margin: 2rem auto 0;
    }
  }
}
