.thanks-img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 30rem;

  svg {
    width: 100%;
    max-width: 30rem;
    transform: translate(2.5rem, -2.5rem);
  }
}
