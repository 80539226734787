html, body {
  background-color: $white;
  color: $dark-gray;
  font-family: $sans;
  font-size: 16px;
  font-weight: $regular;
  height: 100%;
  line-height: 1.5;
  position: relative;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: ' ';
    display: block;
  }
}
